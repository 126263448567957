import { useState } from 'react';
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper';
import 'swiper/css/autoplay';
import FindRoute from '../../Global/FindRoute';
import { useNavigate } from 'react-router-dom';

SwiperCore.use([Pagination, Autoplay, EffectFade]);

function HeaderBanner() {
  const navigate = useNavigate();
  const [optionFilter, setOptionFilter]: any = useState({
    state: '',
    price_from: '0',
    price_to: '25000',
    priceRange: [
      { id: 1, value: '0,25000', label: '$0 - $25,000' },
      // { id: 2 },
      // { id: 3 },
      // { id: 4 },
      // { id: 5 },
      // { id: 6 },
    ],
    limit: 100,
  });

  const onSelectState = (data: any) => {
    setOptionFilter({
      ...optionFilter,
      price_from: 0,
      price_to: 0,
      state_code: data.value,
      state: data.value,
    });
  };

  // const onSelectPriceRange = (data: any) => {
  //   setOptionFilter({
  //     state: {
  //       price_from: data.value.split(',')[0],
  //       price_to: data.value.split(',')[1],
  //       state_code: null,
  //     },
  //   });
  // };
  const onSelectPriceRange = (data: any) => {
    const newValue = { id: data?.id, value: data.value, label: data.label };

    setOptionFilter((prev: any) => {
      // const newPrice = prev?.priceRange?.map((d: any) => {
      //   if (d.id === data?.id) {
      //     return newValue;
      //   }

      //   const newVale = { id: d.id };
      //   return d;
      // });
      // : { id: 1, value: '0,25000', label: '$0 - $25,000' };

      return {
        ...prev,
        price_from: data.value.split(',')[0],
        price_to: data.value.split(',')[1],
        priceRange: /* newPrice */ [newValue],
        state_code: null,
        state: null,
      };
    });
  };

  const SubmitFormFind = () => {
    return navigate('/buy-route', { state: optionFilter });
  };

  return (
    <>
      <div className="container wrap-header">
        <div className="inner-banner">
          <div className="container">
            <h1 className="first-title">Real Routes, </h1>
            <h1>Unlimited Earnings.</h1>
            <p className="first-title">
              Unlock earnings with our curated and verified delivery routes.
            </p>
            <p>Prove your success and boost your profits effortlessly.</p>
            <div className="filter-desktop-responsive">
              <FindRoute
                onSelectState={onSelectState}
                onSelectPriceRange={onSelectPriceRange}
                SubmitFormFind={SubmitFormFind}
                optionFilter={optionFilter}
              />
            </div>
          </div>
        </div>
        <div className="filter-mobile-responsive">
          <FindRoute
            onSelectState={onSelectState}
            onSelectPriceRange={onSelectPriceRange}
            SubmitFormFind={SubmitFormFind}
            optionFilter={optionFilter}
          />
        </div>
      </div>
    </>
  );
}

export default HeaderBanner;
