import { AxiosResponse } from 'axios';
import ServerResponse from '../models/ServerResponse';
import axiosClient from './baseApi';

export const routingQueryKey: any = {
  getAllRoute: '/route/list',
  getRoutesPopular: '/route/popular',
  getMasterRoute: '/routing/get-route',
  getSimilarRoute: '/routing/get-similar-route',
  getById: '/routing/find-route',
};

export const getAllRoute = async () => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>(
      routingQueryKey.getAllRoute,
    );
    return await Promise.resolve(res.data);
  } catch (x) {
    return await Promise.reject(x);
  }
};

export const getMasterRoute = async (params: any) => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>(
      routingQueryKey.getMasterRoute,
      { params },
    );
    return await Promise.resolve(res.data);
  } catch (x) {
    return await Promise.reject(x);
  }
};

export const getSimilarRoute = async (params: any) => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>(
      routingQueryKey.getSimilarRoute,
      { params },
    );
    return await Promise.resolve(res.data);
  } catch (x) {
    return await Promise.reject(x);
  }
};

export const getRoutesPopular = async () => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>(
      routingQueryKey.getRoutesPopular,
    );
    return await Promise.resolve(res.data);
  } catch (x) {
    return await Promise.reject(x);
  }
};
// export const getById = async (id: any) => {
//   try {
//     const res = await axiosClient.get<AxiosResponse<any>>(`/route/${id}`);

//     return await Promise.resolve(res.data);
//   } catch (x) {
//     return await Promise.reject(x);
//   }
// };

export const getById = async (id: number) => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      routingQueryKey.getById,
      { params: { master_route_id: id } },
    );

    return await Promise.resolve(res.data);
  } catch (x) {
    return await Promise.reject(x);
  }
};
