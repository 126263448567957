import React from 'react';
import HeaderBanner from './HeaderBanner';
import FooterBanner from './FooterBanner';
import PopularRoute from './PopularRoute';
import Intro from './Intro';
import Efficiency from './Efficiency';
import RouteMake from './RouteMake';

const Index = () => {
  return (
    <>
      <HeaderBanner />

      <PopularRoute />

      <Intro />

      <Efficiency />

      <div className="bg-footer-content pt-80">
        <RouteMake />
        <FooterBanner />
      </div>
    </>
  );
};

export default Index;
