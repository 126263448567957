import React from 'react';
import { Link } from 'react-router-dom';
import RangeWorkingPerWeek from '../../Global/RangeGlobal/rangeWorkingPerWeeks';
import RangeYearsOfExperience from '../../Global/RangeGlobal/rangeYearsOfExperience';
import SelectFamiliarRetailer from '../../../hooks/useFilterFamiliarBrand/SelectFamiliarRetailer';
import SelectFamiliarBrand from '../../../hooks/useFilterFamiliarRetailers/SelectFamiliarBrand';

function RouteMake() {
  const onSelectFamiliarRetailer = (data: any) => {
    return data;
  };
  return (
    <>
      <div className="container wrap-route-make pt-80 pb-80">
        <div className="row d-flex justify-content-center g-4 ">
          {/* Left */}
          <div className="col-lg-7 col-md-10">
            <div
              className="about-content wow fadeInDown wrap-route-make wrap-route-make-cs"
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
            >
              <h1>How much can your route make?</h1>
              <div className="wrap-range-select">
                <div className="mt-4">
                  <RangeWorkingPerWeek />
                </div>
                <div className="mt-4">
                  <RangeYearsOfExperience />
                </div>
                <div className="mt-5">
                  <SelectFamiliarRetailer
                    value={''}
                    onChange={onSelectFamiliarRetailer}
                  />
                </div>
                <div className="mt-4">
                  <SelectFamiliarBrand
                    value={''}
                    onChange={onSelectFamiliarRetailer}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right */}
          <div className="col-lg-5 col-md-10">
            <div className="about-img-area wrap-right-route-make">
              <div className="wrap-week-sale">
                <h4>Your expected weekly sales:</h4>

                <h1>$20,000 – $30,000</h1>

                <hr />
              </div>

              <div className="mb-5">
                <div
                  className="wow fadeInDown"
                  data-wow-duration="1.5s"
                  data-wow-delay=".2s"
                >
                  <div className="row wrap-expected">
                    <div className="col-xl-6 col-lg-12 col-md-6">
                      <p>Expected weekly commission</p>
                      <h5>$3,000 – $4,500</h5>
                    </div>

                    <div className="col-xl-6 col-lg-12 col-md-6">
                      <p>Expected Route value</p>
                      <h5>$240,000 – $360,500</h5>
                    </div>
                  </div>
                </div>
              </div>

              <Link
                to={'/buy-route'}
                className="eg-btn btn--primary btn--md btn-buy-route"
              >
                Buy a route
              </Link>
            </div>

            <p className="txt-route-mark-cal">
              The calculations provided by this online tool are purely
              estimations and should not be relied upon for critical decisions
              or official computations.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default RouteMake;
