export const DEFAULT_BASE_URL = '';
export const DEFAULT_BASE_API_LOCAL = 'http://localhost:3000/api/v1';
export const DEFAULT_BASE_API =
  process.env.REACT_APP_API_URL || 'http://localhost:3000/api/v1';
export const TOKEN = '6route6';
export const INPUT_MIN_LENGTH = 4;
export const INPUT_MAX_LENGTH = 120;
export const MIN_FILE_SIZE_BYTES = 153600;
export const MAX_FILE_SIZE_BYTES = 8192000;

export const STATUS_LOADING = 'loading';
export const STATUS_ERROR = 'error';
