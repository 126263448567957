import { useState } from 'react';
import 'rsuite/styles/index.less';
import '../css/RangeGlobal.css';
import InputRange from 'react-input-range';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-input-range/lib/css/index.css';

const RangeWorkingPerWeek = () => {
  const [rangeWorkingPerWeek, setRangeWorkingPerWeek]: any = useState(0);
  return (
    <div className="wrap-range-working-days mb-2">
      <div className="range-select-val">{rangeWorkingPerWeek}</div>
      <div className="txt-working-days-per-week">Working days per week</div>
      <InputRange
        maxValue={7}
        minValue={0}
        value={rangeWorkingPerWeek}
        onChange={(value: any) => {
          setRangeWorkingPerWeek(value);
        }}
      />
    </div>
  );
};
export default RangeWorkingPerWeek;
