import React from 'react';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { Outlet } from 'react-router-dom';

const HomeLayout = () => {
  return (
    <>
      <Header />
      <div className="wrap-home-content pt-60">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default HomeLayout;
