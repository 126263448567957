import Select from 'react-select';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { retailerQueryKey, getFilter } from '../../services/apiRetail';

interface stateProp {
  value: any | null;
  onChange: (value: any | null) => void;
}

const SelectFamiliarRetailer = ({ value, onChange }: stateProp) => {
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useQuery<any>(
    [retailerQueryKey?.getFilterRetailerKey],
    () => {
      setIsLoading(true);
      return getFilter();
    },
    {
      onSuccess: () => {
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    },
  );

  const options: any =
    data?.map((retailObj: any) => ({
      value: retailObj?.retail_id,
      label: retailObj?.retail_name,
    })) ?? [];

  return (
    <Select
      className="select-filter-items custom-inner w-100 filter-h-r6r"
      classNamePrefix="select"
      options={options}
      placeholder="Choose Retailer"
      onChange={onChange}
      isLoading={isLoading}
    />
  );
};

export default SelectFamiliarRetailer;
