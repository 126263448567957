import { AxiosResponse } from 'axios';
import axiosClient from './baseApi';

// url
export const brandBaseUrl: any = {
  getAll: '/brand/get-all',
  getFilter: '/brand/get-filter',
};

// key
export const brandQueryKey: any = {
  getAllBrandAllKey: 'getAllBrandAllKey',
  getFilterBrandKey: 'getFilterBrandKey',
};

export const getFilter = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      brandBaseUrl?.getFilter,
    );
    return await Promise.resolve(res?.data);
  } catch (e: any) {
    return await Promise.reject(e);
  }
};
