import { useState } from 'react';
import InputRange from 'react-input-range';

const RangeYearsOfExperience = () => {
  const [rangeYearsExperience, setRangeYearsExperience]: any = useState(20);

  return (
    <div className="wrap-range-working-days mb-2">
      <div className="range-select-val">{rangeYearsExperience}</div>
      <div className="txt-working-days-per-week">Years of experience</div>
      <InputRange
        maxValue={20}
        minValue={0}
        value={rangeYearsExperience}
        onChange={(value: any) => {
          setRangeYearsExperience(value);
        }}
      />
    </div>
  );
};

export default RangeYearsOfExperience;
