import CryptoJS from 'crypto-js';
import icoUser from '../../src/assets/images/user.svg';
import icoStar from '../../src/assets/images/star.svg';
import icoAlertBlue from '../../src/assets/images/info.svg';

const capitalizeFirstLetter = (string: string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

const moneyFormat = (price: any, hasPrefix: boolean) => {
  const dollar = hasPrefix ? '$' : '';
  if (parseFloat(price) < 0) {
    const newPrice = price?.toString()?.replace('-', '');
    return `-${dollar}${numberWithCommas(newPrice)}`;
  } else {
    return `${dollar}${numberWithCommas(price)}`;
  }
};

const numberWithCommas = (num: any) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const iconTopic = (type: string): string => {
  switch (type) {
    case 'new':
      return icoAlertBlue;
    case 'featured':
      return icoStar;
    case 'verified':
      return icoUser;
    default:
      return '';
  }
};

export function EncryptedId(id: any) {
  const encryptedValue = CryptoJS.AES.encrypt(
    id?.toString(),
    'eA8m58Lwkb',
  ).toString();
  // Replace "/" with "_"
  const sanitizedValue = encryptedValue.replace(/\//g, '_');
  return sanitizedValue;
}

export function DecryptedId(id: any) {
  // Reverse the replacement (replace "_" with "/")
  const originalEncryptedValue = id?.replace(/_/g, '/');
  const decrypted = CryptoJS.AES.decrypt(
    originalEncryptedValue?.toString(),
    'eA8m58Lwkb',
  );
  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
  return decryptedText;
}

export { capitalizeFirstLetter, moneyFormat, numberWithCommas, iconTopic };
