import { Link } from 'react-router-dom';
// import icoBtnFindMoreRoute from '../../../assets//images/ico-btn-find-more-route.svg';
import { getMasterRoute, routingQueryKey } from '../../../services/apiRouting';
import { useQuery } from 'react-query';
import LoadingSkeleton from '../../Global/Loading/LoadingSkeleton/LoadingSkeleton';
import { useState } from 'react';
import { MasterRouteMap } from './Map/MasterRouteMap';
import icoFind from '../../../assets/images/icoFindRoutes.svg';

const PopularRoute = () => {
  const [routeFilter] = useState({
    limit: 4,
    price_min: 0,
    price_max: 0,
    show_less: true,
  });

  const { data: masterRouteData, isFetching: isFetchingMasterRoute } =
    useQuery<any>([routingQueryKey?.getMasterRoute], () =>
      getMasterRoute(routeFilter),
    );

  // const BtnFindMoreRoute = () => {
  //   return (
  //     <>
  //       <div
  //         data-wow-duration="1.5s"
  //         data-wow-delay="0.2s"
  //         className="wow fadeInDown"
  //       >
  //         <Link
  //           to={'/buy-route'}
  //           onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
  //         >
  //           <div
  //             data-wow-duration="1.5s"
  //             data-wow-delay="0.2s"
  //             className="wow fadeInDown"
  //           >
  //             <div className="auction-img-container">
  //               <img
  //                 className="auction-img"
  //                 src={icoBtnFindMoreRoute}
  //                 alt="6route6"
  //               />
  //             </div>

  //             <div className="auction-content text-center">
  //               <h4>Find more routes</h4>
  //             </div>
  //           </div>
  //         </Link>
  //       </div>
  //     </>
  //   );
  // };

  return (
    <>
      <div className="testimonial-section pb-80 mb-120">
        <div className="container">
          {/* Title */}
          <div className="text-center m-5">
            <h2>Check our popular routes</h2>
          </div>

          <div className="row d-flex justify-content-center position-relative">
            {isFetchingMasterRoute ? (
              <LoadingSkeleton />
            ) : (
              <>
                {/* <Swiper {...propSlider} className="swiper testimonial-slider2">
                  <div className="swiper-wrapper"> */}
                <div className="row justify-content-center">
                  {/* {data?.data?.map((route: any, index: number) => {
                    return (
                      <BtnRouteName route={route} idx={index} />
                    );
                  })} */}

                  {masterRouteData?.data?.data?.map(
                    (route: any, index: number) => {
                      return (
                        <MasterRouteMap
                          masterRoute={route}
                          idx={index}
                          key={`popular-route-${route?.name}`}
                        />
                      );
                    },
                  )}
                  <div className="col-lg-c-2 col-md-6 col-sm-10 mobile-margin find-more-view d-flex align-items-center">
                    <div
                      data-wow-duration="1.5s"
                      data-wow-delay="0.2s"
                      className="eg-card auction-card1"
                    >
                      <Link
                        to={'/buy-route'}
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: 'smooth' })
                        }
                      >
                        <div className="auction-content">
                          <div className="auction-content text-center">
                            <img src={icoFind} alt="" />
                            <h4>Find more routes</h4>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                  {/* <SwiperSlide className="btn-find-more-route">
                      <div
                        className="wow fadeInDown wrap-btn-find-more-route"
                        data-wow-duration="1.5s"
                        data-wow-delay="0.3s"
                      >
                        <BtnFindMoreRoute />
                      </div>
                    </SwiperSlide> */}
                  {/* <BtnFindMoreRoute /> */}
                </div>
                {/* </div>
                </Swiper> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PopularRoute;
