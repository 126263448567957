import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper';
import 'swiper/css/autoplay';
import { Link } from 'react-router-dom';
import icoLock from '../../../assets/images/banner/ico-lock-footer-banner.svg';

SwiperCore.use([Pagination, Autoplay, EffectFade]);

function FooterBanner() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <>
      <div className="container wrap-header  pt-80 pb-80">
        <div className="inner-banner-2">
          <div className="container">
            <img src={icoLock} alt="6Route6" />

            <h1>Experience Secured</h1>

            <p className="first-title">
              Trade with confidence. We prioritize security, verifying all
              sellers for
            </p>
            <p className="secund-title">
              an unwaveringly trustworthy and secure route marketplace.
            </p>

            <Link
              to={'/buy-route'}
              onClick={scrollTop}
              className="eg-btn btn--lg m-2 wrap-btn-buy-route"
            >
              Buy a route
            </Link>

            <Link
              to={'/sell-route'}
              onClick={scrollTop}
              className="eg-btn btn--lg wrap-btn-sell-route"
            >
              Sell a route
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterBanner;
