import React from 'react';
import { Link } from 'react-router-dom';
import icoIntro1 from '../../../assets//images/intro-1.svg';
import icoIntro2 from '../../../assets//images/intro-2.svg';
import icoIntro3 from '../../../assets//images/intro-3.svg';

const Intro = () => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <>
      <div className="container wrap-intro pb-60">
        <div className="row d-flex justify-content-center">
          <div className="col-sm-12 col-md-10 col-lg-8 col-xl-8">
            <div className="section-title1">
              <h2>Our routes are waiting for you</h2>
              <p className="mb-0">
                6ROUTE6 offers an extensive selection of premium delivery routes
                for ambitious
              </p>
              <p>partners seeking to maximize their earnings.</p>
            </div>
          </div>
        </div>

        <div className="row d-flex justify-content-center g-4">
          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="single-feature wow fadeInDown">
              <div className="icon">
                <img src={icoIntro1} alt="" />
              </div>
              <div className="content">
                <h5>
                  <Link to={'live-auction'} onClick={scrollTop}>
                    Sell quickly
                  </Link>
                </h5>
                <p className="para">
                  Maximize your profit, effortlessly. Sell your driving routes
                  on our marketplace to a ready network of buyers. Start now!
                </p>
              </div>

              <Link
                to={'/sell-route'}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                className="link-to"
              >
                Sell a route {'>'}
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="single-feature wow fadeInDown">
              <div className="icon">
                <img src={icoIntro2} alt="" />
              </div>
              <div className="content">
                <h5>
                  <Link to={'/buy-route'}>Buy successfully</Link>
                </h5>
                <p className="para">
                  Designed with user-friendly navigation, our platform allows
                  you to easily find and secure the most profitable routes in
                  your area.
                </p>
              </div>

              <Link
                to={'/buy-route'}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                className="link-to"
              >
                Find a route {'>'}
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-10">
            <div className="single-feature wow fadeInDown">
              <div className="icon">
                <img src={icoIntro3} alt="" />
              </div>
              <div className="content">
                <h5>
                  <Link to={'/relief-drivers'} onClick={scrollTop}>
                    Find replacement
                  </Link>
                </h5>
                <p className="para">
                  Navigate your holidays or sick days stress-free. Our platform
                  swiftly connects you to reliable replacements, ensuring your
                  routes run uninterrupted.
                </p>
              </div>

              <Link
                to={'relief-drivers'}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                className="link-to"
              >
                Drivers relief {'>'}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
