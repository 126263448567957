import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import moment from 'moment';
import logo from '../../assets/images/logo-footer.svg';

const Footer = (props: any) => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <>
      <footer>
        {/* Section 1 */}
        <div className="footer-top">
          <div className="container">
            <div className="row gy-5">
              <div className="col-lg-8 col-md-6">
                <div className="footer-item">
                  <h5>
                    <img src={logo} alt="6route6" />
                  </h5>
                  <ul className="footer-list">
                    <li>
                      <Link to={``} onClick={scrollTop}>
                        The trusted marketplace for your route.
                      </Link>
                    </li>
                  </ul>

                  <div className="row d-flex align-items-center g-4 footer-copy-right">
                    <div className="col-lg-12 d-flex justify-content-lg-start justify-content-center">
                      <p>
                        Copyright © <Link to={'/'}>6ROUTE6</Link>{' '}
                        {moment().format('Y')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 d-flex justify-content-lg-center">
                <div className="footer-item">
                  <ul className="footer-list">
                    <li>
                      <Link to={'buy-route'} onClick={scrollTop}>
                        Buy a Route
                      </Link>
                    </li>
                    {/* <li>
                      <Link to={'sell-route'} onClick={scrollTop}>
                        Sell a route
                      </Link>
                    </li>
                    <li>
                      <Link to={'sell-route'} onClick={scrollTop}>
                        Relief Drivers
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-6">
                <div className="footer-item">
                  <ul className="footer-list">
                    <li>
                      <Link to={'/faq'} onClick={scrollTop}>
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link to={'/terms-and-agreements'} onClick={scrollTop}>
                        Terms and Agreements
                      </Link>
                    </li>
                    <li>
                      <Link to={'/contact-us'} onClick={scrollTop}>
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
