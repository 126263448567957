import React from 'react';
import './LoadingSpinner.css'; // Import the CSS file

function Preloader(props: any) {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );

  // return (
  //   <>
  //     <div className={`${props.styles}`}>
  //       <div className="loader">
  //         <span></span>
  //         <span></span>
  //         <span></span>
  //         <span></span>
  //       </div>
  //     </div>
  //   </>
  // );
}

export default Preloader;
