import Select from 'react-select';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { brandQueryKey, getFilter } from '../../services/apiBrand';

interface stateProp {
  value: any | null;
  onChange: (value: any | null) => void;
}

const SelectFamiliarBrand = ({ value, onChange }: stateProp) => {
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useQuery<any>(
    [brandQueryKey?.getFilterBrandKey],
    () => {
      setIsLoading(true);
      return getFilter();
    },
    {
      onSuccess: () => {
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    },
  );

  // const selectedValues: any[] = value ? value.split(',') : [];

  const options: any =
    data?.data?.map((brandObj: any) => ({
      value: brandObj?.cpg_brand_id,
      label: brandObj?.cpg_brand_name,
    })) ?? [];

  return (
    <Select
      className="select-filter-items custom-inner w-100 filter-h-r6r"
      classNamePrefix="select"
      options={options}
      placeholder="Choose Brand"
      onChange={onChange}
      isLoading={isLoading}
    />
  );
};

export default SelectFamiliarBrand;
