import React from 'react';
import { Link } from 'react-router-dom';
import efficiencyRight from '../../../assets//images/efficiency-right.svg';

const Efficiency = () => {
  return (
    <>
      <div className="container wrap-section-efficiency pt-120 pb-120">
        <div className="row d-flex justify-content-center g-4">
          {/* Left */}
          <div className="col-lg-6 col-md-10">
            <div
              className="about-content wow fadeInDown left-content"
              data-wow-duration="1.5s"
              data-wow-delay=".2s"
            >
              <h2>Efficiency Unleashed</h2>
              <p>
                Harness the power of data-driven insights, personalized
                recommendations, and seamless route integration to elevate your
                delivery business, streamline operations, and experience
                unparalleled success.
              </p>

              <p>
                Join our community of satisfied delivery partners and unlock
                your true earning potential today.
              </p>

              <Link to={'/buy-route'} className="eg-btn btn--primary btn--md">
                Find your route
              </Link>
            </div>
          </div>

          {/* Right */}
          <div className="col-lg-6 col-md-10 right-content">
            <div className="about-img-area">
              <img
                src={efficiencyRight}
                className="img-fluid about-img wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay=".2s"
                alt="about-img"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Efficiency;
