import React, { lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import HomeLayout from './components/layout/HomeLayout';
import Preloader from './components/layout/Preloader';
import Home from './components/page/home/Index';
import Layout from './components/App';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import AuthenProvider, { RequireAuthen } from './authen/AuthenProvider';
// import ScrollToTop from './hooks/srcollTop/scrollToTop';

const container: HTMLElement | any = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24,
      refetchOnWindowFocus: false,
    },
  },
});

const BuyRoute = lazy(() => import('./components/page/buyRoute/BuyRoute'));
const SellRoute = lazy(() => import('./components/page/sellRoute/SellRoute'));
const ReliefDrivers = lazy(
  () => import('./components/page/reliefDrivers/ReliefDrivers'),
);
const Contact = lazy(() => import('./components/page/contact/Contact'));
const RouteDetails = lazy(
  () => import('./components/page/home/RouteDetails/RouteDetails'),
);

const Error401 = lazy(
  () => import('./components/page/Authentication/ErrorPage/Error401/Error401'),
);
const Error403 = lazy(
  () => import('./components/page/Authentication/ErrorPage/Error403/Error403'),
);
const Error404 = lazy(
  () => import('./components/page/Authentication/ErrorPage/Error404/Error404'),
);
const Error503 = lazy(
  () => import('./components/page/Authentication/ErrorPage/Error503/Error503'),
);
const Error400 = lazy(
  () => import('./components/page/Authentication/ErrorPage/Error400/Error400'),
);
const Error500 = lazy(
  () => import('./components/page/Authentication/ErrorPage/Error500/Error500'),
);
const ForgotPassword = lazy(
  () =>
    import('./components/page/Authentication/ForgotPassword/ForgotPassword'),
);
const Login = lazy(
  () => import('./components/page/Authentication/Login/Login'),
);
const Register = lazy(
  () => import('./components/page/Authentication/Register/Register'),
);

const ErrorPages = lazy(() => import('./components/page/ErrorPages'));

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthenProvider>
          <React.Suspense fallback={<Preloader styles="preloader" />}>
            <Routes>
              <Route
                path="/"
                element={
                  <RequireAuthen>
                    <HomeLayout />
                  </RequireAuthen>
                }
              >
                <Route path={'/'} element={<Home />} />
              </Route>

              <Route
                path={'/'}
                element={
                  <RequireAuthen>
                    <Layout />
                  </RequireAuthen>
                }
              >
                <Route
                  path={'/route/:id'}
                  element={
                    <RequireAuthen>
                      <RouteDetails />
                    </RequireAuthen>
                  }
                />
                <Route path="/buy-route" element={<BuyRoute />} />
                <Route path="/sell-route" element={<SellRoute />} />
                <Route path="/relief-drivers" element={<ReliefDrivers />} />
                <Route path="/contact" element={<Contact />} />
              </Route>

              {/* Authentication Error Pages */}
              <Route
                path={`${process.env.PUBLIC_URL}`}
                element={<ErrorPages />}
              >
                <Route path={'error-400'} element={<Error400 />} />
                <Route path={'error-401'} element={<Error401 />} />
                <Route path={'error403'} element={<Error403 />} />
                <Route path={'error-404'} element={<Error404 />} />
                <Route path={'error-500'} element={<Error500 />} />
                <Route path={'error-503'} element={<Error503 />} />,
              </Route>

              <Route path={'/'} element={<HomeLayout />}>
                <Route path={'login'} element={<Login />} />
                <Route path={'forgot-password'} element={<ForgotPassword />} />
                <Route path={'register'} element={<Register />} />
              </Route>
            </Routes>
          </React.Suspense>
        </AuthenProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
);

reportWebVitals();
