import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useState } from 'react';
import { mapStyles } from './MapStyle';
import { Link } from 'react-router-dom';
import MARKER_ICON from '../../../../assets/images/marker-blue.svg';
import { moneyFormat, EncryptedId } from '../../../../utils/global';

export const containerStyle = {
  height: '190px',
  width: '100%',
};

export const map_option = {
  maxZoom: 20,
  minZoom: 1,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  clickableIcons: false,
  gestureHandling: 'greedy',
  zoomControl: false,
  styles: mapStyles,
};

export const ZOOM = 10;

export const MasterRouteMap = ({ masterRoute }: any) => {
  const masterRouteId = EncryptedId(masterRoute?.id);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY as string,
  });

  const [, setMap] = useState<google.maps.Map | null>();
  const [centerPosition, setCenterPosition]: any = useState();

  const MasterRouteMarker = () => {
    const size = new google.maps.Size(25, 30, 'px', 'px');

    const subOptions: google.maps.MarkerOptions = {
      icon: {
        url: MARKER_ICON,
        size: size,
        scaledSize: size,
      },
      zIndex: 10,
      optimized: true,
    };

    return (
      <Marker
        key={`master-marker-${masterRoute.name}`}
        position={centerPosition}
        //   onClick={() => onStoreNormalClick(store)}
        options={subOptions}
        //   clusterer={cluster}
      ></Marker>
    );
  };

  const onLoadMap = (map: any) => {
    setMap(map);
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    masterRoute?.route?.forEach((r: any) => {
      r.store?.forEach((store: any) => {
        const position = {
          lat: Number(store.lat),
          lng: Number(store.lng),
        };
        bounds.extend(position);
      });
    });
    map?.fitBounds(bounds);
    setCenterPosition(bounds.getCenter());
  };

  const onUnmount = () => {};

  const onMapClick = () => {};

  return (
    <>
      <div className="col-lg-c-2 col-md-6 col-sm-10 mobile-margin">
        <div
          data-wow-duration="1.5s"
          data-wow-delay="0.2s"
          className="eg-card auction-card1 wrap-map-master-route"
        >
          <div className="">
            {isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                // center={center}
                onLoad={onLoadMap}
                onUnmount={onUnmount}
                onClick={onMapClick}
                options={map_option}
                zoom={ZOOM}
              >
                <MasterRouteMarker />
              </GoogleMap>
            ) : (
              <></>
            )}
          </div>

          <div className="auction-content">
            <div className="wrap-title-route-name">
              <Link
                className="title-route-name"
                to={'#'}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                <p className="title">{masterRoute?.name}</p>
              </Link>

              <p className="price title-route-price">
                Price:{' '}
                {masterRoute?.price
                  ? moneyFormat(masterRoute?.price?.toFixed(2), true)
                  : '-'}
              </p>

              <p className="state title-route-state">
                {masterRoute?.route?.length > 0
                  ? masterRoute?.route[0]?.store[0]?.city
                  : 'N/A'}
                ,{' '}
                {masterRoute?.route?.length > 0
                  ? masterRoute?.route[0]?.store[0]?.state
                  : 'N/A'}
              </p>
            </div>
            <div className="auction-card-bttm wrap-btn-access wrap-map-master-route">
              <Link
                to={`/route/${masterRouteId}`}
                className="eg-btn btn--primary btn--sm btn-access-route"
              >
                Get Access
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
