import React, { useCallback, useEffect, useState } from 'react';
import { login /* logout */ } from '../services/authenService';
import { setAxiosAuthToken } from '../services/baseApi';
import LoginRequest from '../services/LoginRequest';
import { currentUser } from '../services/apiUser';
import { TOKEN } from '../utils/constants';
// import { Navigate, useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import LoginResponse from '../services/LoginResponse';
// import Preloader from '../components/layout/Preloader';

// Interface
interface IAuthenContext {
  signIn: any;
  signOut: any;
  userInfo: any;
  reFetch: any;
  status: 'idle' | 'error' | 'loading' | 'success';
}

// init Authen context
let AuthenContext = React.createContext<IAuthenContext>(null!);

export default function AuthenProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // hook
  const [userInfo, setUserInfo]: any = useState<LoginResponse>();

  // local storage
  const userToken = localStorage.getItem(TOKEN);

  const navigate = useNavigate();

  const [status, setStatus] = useState<
    'idle' | 'error' | 'loading' | 'success'
  >('idle');

  const fetchUserData = useCallback(async () => {
    try {
      setStatus('loading');
      const res = await currentUser();
      setUserInfo(res);
      setStatus('success');
    } catch {
      setStatus('error');
      localStorage.removeItem(TOKEN);
      localStorage.removeItem('user');
      // localStorage.removeItem('userId');
    }
    // eslint-disable-next-line
  }, [userToken]);

  // first load
  useEffect(() => {
    // get token from local storage
    const token = localStorage.getItem(TOKEN);

    // if has
    if (token) {
      // update axios token
      setAxiosAuthToken(token);

      // get user again
      fetchUserData().then();
    }
  }, [fetchUserData]);

  // sign in
  const signIn = async (loginReq: LoginRequest) => {
    try {
      // call Axios API
      const res = await login(loginReq);
      if (res.data?.data?.success) {
        return res;
      }

      const data = res?.data?.data;
      setAxiosAuthToken(data?.accessToken);

      setUserInfo(data?.user);

      localStorage.setItem(TOKEN, data?.accessToken);
      localStorage.setItem('user', JSON.stringify(data?.user));
      localStorage.setItem('userId', JSON.stringify(data?.user?.id));

      return res;
    } catch (err: any) {
      localStorage.removeItem('user');
      setUserInfo(undefined);
      return Promise.reject(err?.data);
    }
  };

  const signOut = useCallback(() => {
    setUserInfo(undefined);
    localStorage.removeItem(TOKEN);
    localStorage.removeItem('user');
    return navigate('/login');
  }, [navigate]);

  // return
  const providerValue = {
    userInfo,
    signIn,
    signOut,
    status,
  };

  // return provider
  return (
    <AuthenContext.Provider
      value={{
        ...providerValue,
        reFetch: fetchUserData,
      }}
    >
      {children}
    </AuthenContext.Provider>
  );
}

export const useAuthen = (): IAuthenContext => {
  return React.useContext(AuthenContext);
};

export function RequireAuthen({ children }: { children: JSX.Element }) {
  // let { userInfo, status } = useAuthen();
  // const token = localStorage.getItem(TOKEN);

  // // no login
  // if (!token) {
  //   return <Navigate to="/login" />;
  // }

  // if (status === 'loading' || status === 'idle')
  //   return <Preloader styles="preloader" />;

  // if (userInfo) {
  //   <>
  //     <Preloader styles="preloader" />
  //   </>;
  // }

  return children;
}
