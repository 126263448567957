import React, { useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo-header.svg';
// import icoProfile from '../../assets/images/icons/ico-profile-circle.svg';
// import { useAuthen } from '../../authen/AuthenProvider';

const Header = () => {
  const [sidebar, setSidebar] = useState(false);

  /*---------menu button event----------*/
  const handleSidBarMenu = () => {
    if (!sidebar) {
      setSidebar(true);
    } else {
      setSidebar(false);
    }
  };

  /*---------add event scroll top----------*/
  const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  /*---------Using reducer mange the active or inactive menu----------*/
  const initialState = { activeMenu: '' };
  const [state, dispatch] = useReducer(reducer, initialState);

  // const { userInfo } = useAuthen();

  function reducer(state: any, action: any) {
    switch (action.type) {
      case 'home':
        return { activeMenu: 'home' };
      case 'buy-route':
        return { activeMenu: 'buy-route' };
      case 'sell-route':
        return { activeMenu: 'sell-route' };
      case 'relief-drivers':
        return { activeMenu: 'relief-drivers' };
      case 'contact':
        return { activeMenu: 'contact' };
      case 'login':
        return { activeMenu: 'login' };
      default:
        return { activeMenu: '' };
    }
  }

  return (
    <>
      <header className="header-area style-1">
        <div className="container">
          <div className="header-logo">
            <Link to={'/'} onClick={scrollTop}>
              <img alt="6ROUTE6" src={logo} />
            </Link>
          </div>

          <div className={sidebar ? 'main-menu show-menu' : 'main-menu'}>
            <div className="mobile-logo-area d-lg-none d-flex justify-content-between align-items-center">
              <div className="mobile-logo-wrap ">
                <Link to={'/'}>
                  <img alt="6ROUTE6" src={logo} />
                </Link>
              </div>
              <div className="menu-close-btn" onClick={handleSidBarMenu}>
                <i className="bi bi-x-lg" />
              </div>
            </div>

            <ul className="menu-list">
              {/* Buy a Route */}
              <li onClick={() => dispatch({ type: 'buy-route' })}>
                <Link
                  to={'buy-route'}
                  onClick={scrollTop}
                  className={`${
                    state.activeMenu === 'buy-route' ? 'active' : ''
                  } `}
                >
                  Buy a Route
                </Link>
              </li>

              {/* Sell a Route */}
              {/* <li onClick={() => dispatch({ type: 'sell-route' })}>
                <Link
                  to={'sell-route'}
                  onClick={scrollTop}
                  className={`${state.activeMenu === 'about' ? 'active' : ''} `}
                >
                  Sell a Route
                </Link>
              </li> */}

              {/* Relief Drivers */}
              {/* <li onClick={() => dispatch({ type: 'relief-drivers' })}>
                <Link
                  to={'/relief-drivers'}
                  onClick={scrollTop}
                  className={`${
                    state.activeMenu === 'relief-drivers' ? 'active' : ''
                  } `}
                >
                  Relief Drivers
                </Link>
              </li> */}

              {/* Contact */}
              <li onClick={() => dispatch({ type: 'contact' })}>
                <Link
                  to={'/contact'}
                  onClick={scrollTop}
                  className={`${
                    state.activeMenu === 'contact' ? 'active' : ''
                  }`}
                >
                  Contact Us
                </Link>
              </li>

              {/* Auth
              {userInfo ? (
                <li onClick={() => dispatch({ type: 'login' })}>
                  <Link
                    to={'/profile'}
                    onClick={scrollTop}
                    className={`${
                      state.activeMenu === 'login' ? 'active' : ''
                    } wrap-logged-in`}
                  >
                    <img src={icoProfile} alt="" className="img-responsive" />
                    {userInfo?.username}
                  </Link>
                </li>
              ) : (
                <li onClick={() => dispatch({ type: 'login' })}>
                  <Link
                    to={'/login'}
                    onClick={scrollTop}
                    className={`${
                      state.activeMenu === 'login' ? 'active' : ''
                    } wrap-contact-menu`}
                  >
                    Login
                  </Link>
                </li>
              )} */}
            </ul>
          </div>

          <div className="nav-right d-flex align-items-center wrap-menu-responsive">
            <div
              className="mobile-menu-btn d-lg-none d-block"
              onClick={handleSidBarMenu}
            >
              <i className="bx bx-menu" />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
