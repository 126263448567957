import SelectState from '../../hooks/useFindRoute/SelectState';
// import SelectPriceRange from '../../hooks/useFilterPriceRange/SelectPriceRange';
import IcoSearch from '../../assets/images/icons/search.svg';
import IcoLocation from '../../assets/images/icons/location.png';
// import IcoCoin from '../../assets/images/icons/dollar.png';
import './css/FindRoute.css';

const FindRoute = ({
  onSelectState,
  onSelectPriceRange,
  SubmitFormFind,
  optionFilter,
}: any) => {
  const BtnFindRoute = () => {
    return (
      <div className="">
        <div className="wrap-btn-find-route d-flex justify-content-center">
          <button
            onClick={() => {
              SubmitFormFind();
            }}
          >
            <img src={IcoSearch} alt="" />
            Find Routes
          </button>
        </div>
      </div>
    );
  };

  // return (
  //   <>
  //     <div className="row">
  //       <div className="col-md-10 col-sm-12">
  //         <div className="row wrap-find-route">
  //           <div className="col-md-4 col-sm-12">
  //             <div className="wrap-filter-padding">
  //               <img src={IcoLocation} alt="" />
  //               <SelectState
  //                 value={optionFilter?.state}
  //                 onChange={(data: any) => {
  //                   onSelectState(data);
  //                 }}
  //               />
  //             </div>
  //           </div>
  //           <div className="col-md-4 col-sm-12">
  //             <div className="wrap-filter-padding">
  //               <img src={IcoCoin} alt="" />
  //               <SelectPriceRange
  //                 value={optionFilter?.state}
  //                 onChange={(data: any) => {
  //                   onSelectPriceRange(data);
  //                 }}
  //               />
  //             </div>
  //           </div>
  //           <div className="col-md-4 col-sm-12">
  //             <BtnFindRoute />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    <div className="d-flex gap-2 wrap-find-route col-md-8">
      <div className="w-80">
        <div className="wrap-filter-padding">
          <img src={IcoLocation} alt="" />
          <SelectState
            value={optionFilter?.state}
            onChange={(data: any) => {
              onSelectState(data);
            }}
          />
        </div>
      </div>
      {/* <div className="w-40">
        <div className="wrap-filter-padding">
          <img src={IcoCoin} alt="" />
          <SelectPriceRange
            value={optionFilter?.state}
            onChange={(data: any) => {
              onSelectPriceRange(data);
            }}
          />
        </div>
      </div> */}
      <BtnFindRoute />
    </div>
  );
};

export default FindRoute;
