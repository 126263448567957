import ServerResponse from '../models/ServerResponse';
import axiosClient from './baseApi';

export const stateQueryKey: any = {
  getAll: 'getAll',
};

export const getAll = async () => {
  try {
    const res = await axiosClient.get<ServerResponse<any>>('/state/get-filter');
    return await Promise.resolve(res.data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
