import { AxiosResponse } from 'axios';
import axiosClient from './baseApi';

// url
export const brandBaseUrl: any = {
  getAll: '/retailer/all',
  getFilter: '/retailer/get-filter',
};

// key
export const retailerQueryKey: any = {
  getAllRetailerKey: 'getAllRetailerKey',
  getFilterRetailerKey: 'getFilterRetailerKey',
};

export const getFilter = async () => {
  try {
    const res = await axiosClient.get<AxiosResponse<any>>(
      brandBaseUrl?.getFilter,
    );
    return await Promise.resolve(res?.data?.data);
  } catch (e: any) {
    return await Promise.reject(e);
  }
};
