import Select from 'react-select';
import { getAll, stateQueryKey } from '../../services/apiState';
import { useState } from 'react';
import { useQuery } from 'react-query';

interface SelectProps {
  value: any | null;
  onChange: (value: any | null) => void;
}

const SelectState = ({ onChange }: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { data } = useQuery<any>(
    [stateQueryKey?.getAll],
    () => {
      setIsLoading(true);
      return getAll();
    },
    {
      onSuccess: () => {
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    },
  );

  const options: any =
    data?.data?.map((state: any) => ({
      label: state?.state_name,
      value: state?.state_code,
    })) ?? [];

  return (
    <Select
      className="select-filter-items custom-inner w-100 filter-h-r6r-t"
      classNamePrefix="select"
      options={options}
      placeholder="Choose state"
      onChange={onChange}
      isLoading={isLoading}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
    />
  );
};

export default SelectState;
